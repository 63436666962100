import type React from 'react';
import { useEffect } from 'react';
import { parentMessage } from '../common/utils';

interface LogoutProps {
  salla: any;
}

const Logout: React.FC<LogoutProps> = ({ salla }) => {
  useEffect(() => {
    const logoutUser = async () => {
      try {
        salla.storage.clearAll();
        salla.cookie.clearAll();

        await salla.auth.event.loggedOut();
        parentMessage({}, 'logged-out');
      } catch (error) {
        console.error('Error logging out:', error);
      }
    };

    logoutUser();
  }, [salla]);

  return <div>Logging out...</div>;
};

export default Logout;
