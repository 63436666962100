import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import Logout from './components/Logout';
import '@salla.sa/twilight';
import './App.scss';
import Loading from './components/Loading';
import useSetupApp from './common/useSetupApp';
import { parentMessage, inIframe, toggleDarkMode } from './common/utils';
const handlMessageWidth = (width: number) => {
  document.documentElement.style.setProperty('--parent-width', `${width}px`);
  width <= 786 && document.body.classList.add('is-mobile');
}

function App() {

  const { salla, isReady, supportWebAuth, withoutReload, startTheApp } = useSetupApp(undefined)

  const container = React.createRef<HTMLDivElement>();

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => parentMessage({ height: container.current?.scrollHeight }, "height"));
    container.current && resizeObserver.observe(container.current);

    const handleMessage = (event: MessageEvent) => {
      if (event.data.source !== 'login' && event.data.source !== 'logout') {
        return;
      }

      const data = event.data.data;
      const action = event.data.type;
      if (action === 'width') {
        return handlMessageWidth(data);
      }
      if (action === 'init') {
        return startTheApp(data);
      }

      if (action === 'darkMode') {
        return toggleDarkMode(data.isDark);
      }
    };

    // Add event listener only once
    window.addEventListener('message', handleMessage);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener('message', handleMessage);
    // eslint-disable-next-line
  }, [salla, container]);

    if (!isReady) {
        return inIframe() ? (
            <div className='flex flex-col items-center justify-center h-full fill-primary'>
                <Loading size="sm" color="primary" />
            </div>
        ) : null;
    }

  return (
    <div className="App" ref={container}>
      <Routes>
        <Route
          path="/"
          element={
            <Login
              isEmailAllowed={salla.config.get('store.settings.auth.email_allowed')}
              isMobileAllowed={salla.config.get('store.settings.auth.mobile_allowed')}
              isEmailRequired={salla.config.get('store.settings.auth.is_email_required')}
              supportWebAuth={supportWebAuth}
              withoutReload={withoutReload}
              salla={salla}
              inline={true}
            />
          }
        />
        <Route path="/logout" element={<Logout salla={salla}/>}/>
      </Routes>
    </div>
  );
}

export default App;
