export const logger = (type: 'log' | 'error' | 'warn' = 'log', ...args: any) => {
    if(!(window as any).salla?.logger){
        return console[type]('%cSalla-Accounts', 'font-weight:bold; border:1px solid #bbf3e5; padding: 2px 6px; border-radius: 5px; color:#bbf3e5', ...args);
    }
    return (window as any).salla?.logger[type](...args);
}
export const log = (...args: any) => {
    return logger('log', ...args);
};

export const error = (...args: any) => {
    return logger('error', ...args);
};

export const warn = (...args: any) => {
    return logger('warn', ...args);
};

export const parentMessage = (message: any, type: 'ready' | 'height' | 'authenticated_web_auth' | 'authenticated' | 'notify' | 'dispatchEvent' | 'storage' | 'reload' | 'logged-out' | string) => {
    type = `iframe::${type}`;
    log('parent.postMessage', type, message);
    return window.parent.postMessage({
        source: "login",
        message: message,
        type: type
    }, "*");
}

export function inIframe(): boolean {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}
  export const toggleDarkMode = (isDark: boolean) =>
  isDark
    ? document.documentElement.classList.add('dark')
    : document.documentElement.classList.remove('dark');