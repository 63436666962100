export function digitsOnly(num: string): string {
    return Number.parseFloat(num).toString().replace(/[^0-9.]/g, '')
        .replace('..', '.')
        .trim();
}

/**
 * @param {HTMLInputElement|string} input - selector or input object
 * @param {boolean} is_decimal
 * @return {void}
 */
export function inputDigitsOnly(input: HTMLInputElement | string, is_decimal = false): void {
    const handleInput = (el: HTMLInputElement) => {
        const new_val = digitsOnly(el.value);

        if (el.min && Number.parseFloat(new_val) < Number.parseInt(el.min)) {
            el.value = el.min;
        } else if (el.max && Number.parseFloat(new_val) > Number.parseInt(el.max)) {
            el.value = el.max;
        } else if (el.maxLength >= 1 && new_val.toString().length > el.maxLength) {
            el.value = new_val.toString().substring(0, el.maxLength);
        } else {
            el.value = is_decimal || el.dataset.hasOwnProperty('digitsWithDecimal')
                ? getOnlyDecimal(new_val)
                : new_val.replace(/\D/g, '');
        }
    };

    if (typeof input === 'string') {
        document.querySelectorAll(input).forEach((el) => handleInput(el as HTMLInputElement));
    } else if (!input) {
        console.warn(`Can't find object with id: ${input}`);
    } else {
        handleInput(input);
    }
}

export function getOnlyDecimal(value: string): string {
    const dotsCount = (`${value}`.match(/\./g) || []).length;
    if (!dotsCount || dotsCount === 1) {
        return value;
    }

    // if there is more than one dots, remove the last dot.
    // Ex: 123.545.66 => 123.54566
    return getOnlyDecimal(value.replace(/\.(.+)\./g, '.$1'));
}