import React, { useRef, useState, forwardRef, useImperativeHandle, useCallback, useEffect } from 'react';
import OTPInput, { type OTPInputRef } from "./OTPInput";
import Loading from './Loading';
interface SallaVerifyProps {
  display?: 'inline' | 'modal';
  type?: 'mobile' | 'email';
  autoReload?: boolean;
  supportWebAuth?: boolean;
  salla: any;
  verified?: (response: unknown) => void;
}
type DataType = {
  type: 'mobile' | 'email',
  phone?: string,
  country_code?: string,
  country_key?: string,
  email?: string
};
const SallaVerify = forwardRef(({ salla, verified, supportWebAuth }: SallaVerifyProps, ref) => {
  const [timer, setTimer] = useState(30);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line
  const [code, setCode] = useState('');
  const [otp, setOtp] = useState('');
  const [data, setData] = useState<DataType>({ type: 'mobile' });
  const otpInputRef = useRef<OTPInputRef | null>(null);
  const bodyRef = useRef<HTMLDivElement>(null);
  const codeRef = useRef<HTMLInputElement>(null);
  const btnRef = useRef<HTMLButtonElement | null>(null);
  const resendMessageRef = useRef<HTMLParagraphElement>(null);
  const timerRef = useRef<HTMLElement>(null);
  const resendRef = useRef<HTMLButtonElement>(null);
  useImperativeHandle(ref, () => ({
    open: (data: DataType) => {
      setData(data);
      resendTimer();
      resetError();
      setTimeout(() => {
        otpInputRef.current?.focusFirstInput();
      }, 100);

    },
    getCode: () => {
      return otp;
    }
  }));
  const resetError = () => {
    setHasError(false);
    setErrorMessage('');
  };


  const resendCode = async () => {
    btnRef.current?.removeAttribute('disabled');
    otpInputRef.current?.reset();
    return Promise.resolve()
      .then(() => salla.api.auth.resend(data))
      .finally(() => resendTimer());
  };
  const handleSubmitOTP = (value: string) => {
    setCode(value);
    const updatedCode = value.split('').map((item: string) => {
      return Number.parseInt(item);
    });

    setOtp(updatedCode.join(''));

  }
  const handleChange = (value: string) => {
    resetError();
    setOtp(value);
  };
  const submit = () => {
    if (otp.length !== 4) {
      console.log('Trying to submit without 4 digits!');
      return;
    }

    // Exclude country_key
    const { country_key, ...restData } = data;
    const dataToSend = { code: otp, ...restData };

    return Promise.resolve()
      .then(() => setIsLoading(true))
      .then(() => salla.auth.verify(dataToSend, false)) // false is supportWebAuth
      .then(response => verified?.(response))
      .then(() => setIsLoading(false))
      .catch((error) => {
        setHasError(true);
        setIsLoading(false);
        otpInputRef.current?.reset();
        setErrorMessage(error.response?.data?.error?.message);
        console.error(error);
        btnRef.current?.removeAttribute('disabled');
      });
  };

  const formatTimer = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');
    return `${formattedSeconds} : ${formattedMinutes}`;
  };
  const resendTimer = useCallback(() => {
    if (resendMessageRef.current) {

      (resendMessageRef.current.style.display = 'block');
    }
    if (resendRef.current) {
      resendRef.current.style.display = 'none'
    }
    setTimer(30);

    const intervalId = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer <= 0) {
          clearInterval(intervalId);
          if (resendRef.current) {
            resendRef.current.style.display = 'block'
          }
          if (resendMessageRef.current) {
            resendMessageRef.current.style.display = 'none'
          }
        }
        return prevTimer - 1;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (otp.length === 4) submit();
    // eslint-disable-next-line
  }, [otp]);

  return (
    <div className="s-verify-body" ref={bodyRef}>
      <div className="s-verify-message" dangerouslySetInnerHTML={{ __html: salla.lang.get('pages.profile.verify_message') }} />
      {(data.email || data.phone) && <div dir="ltr" className="s-verify-current" slot="mobile">{data.type === 'mobile' ? `${data.country_key} ${data.phone}` : data.email}</div>}
      <input type="hidden" name="code" maxLength={4} required ref={codeRef} />

      <OTPInput
        autoFocus
        isNumberInput
        length={4}
        ref={otpInputRef}
        className={`s-verify-codes ${hasError ? 'has-error' : ''}`}
        inputClassName={`s-verify-input ${hasError ? 's-has-error' : ''}`}
        onChangeOTP={(otp) => handleChange(otp)}
        onCompleted={(otp) => handleSubmitOTP(otp)}
      />

      {hasError && errorMessage ? <span className="s-verify-error-message">
        {errorMessage}
      </span> : ''}
      <div slot="footer" className="s-verify-footer">
        <button type='button' className="mb-5 btn btn--primary" disabled={isLoading}
          onClick={submit}
          ref={btnRef}>
          {isLoading ? <Loading size="sm" /> : salla.lang.get('pages.profile.verify')}
        </button>
        <p className="s-verify-resend-message" ref={resendMessageRef}>
          {salla.lang.get('blocks.header.resend_after')}
          <b className="s-verify-timer" ref={timerRef}>{formatTimer(timer)}</b></p>
        <button type='button' className="s-verify-resend" onClick={resendCode} disabled={isLoading} ref={resendRef}>
          {salla.lang.get('blocks.comments.submit')}
        </button>
      </div>
    </div>
  );
});

export default SallaVerify;
