import { useEffect, useMemo } from 'react';
import { parentMessage } from './utils';

function useSalla() {
  const salla = useMemo(() => {
    return (window as any).salla || (window as any).Salla || {};
  }, []);

  useEffect(() => {
    if (salla.is_accounts_loaded) {
      return;
    }

    // Send ready and storage events
    parentMessage({}, 'ready');
    const storageObject = {
      user: salla.storage.get('user'),
      token: salla.cookie.get('s-token') || salla.storage.get('token'),
    }
    //avoid sending undefined by removing it
    storageObject.user || (delete storageObject.user);
    (storageObject.token?.length>10) || (delete storageObject.token);
    Object.keys(storageObject).length && parentMessage(storageObject, 'storage');

    // Setup logger
    salla.logger.addPrefix(['%cSalla-Accounts', 'margin-left: 5px;font-weight:bold; border:1px solid #bbf3e5; padding: 2px 6px; border-radius: 5px; color:#bbf3e5']);

    // Disable cart API call
    salla.api.cart.latest = () => ({ data: { cart: { id: null } } });

    // Set custom notifier
    salla.notify.setNotifier((message: string, type: unknown, data: unknown) =>
      parentMessage({ message, type, data: JSON.parse(JSON.stringify(data)) }, 'notify')
    );

    // Whitelist certain events
    const handleEvent = (event: string, ...payload: unknown[]) => {
      if (['auth::verified', 'profile::info.fetched', 'auth::logged.in', 'api::token.injected'].includes(event)) {
        parentMessage({ event, payload }, 'dispatchEvent');
      }
    };
    salla.event.onAny(handleEvent);

    // Mark accounts as loaded
    salla.is_accounts_loaded = true;

    // Cleanup event listener when the component unmounts
    return () => {
      salla.event.offAny(handleEvent);
    };
  }, [salla]);

  return salla;
}

export default useSalla;
