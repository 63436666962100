import React, { useState } from 'react';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
registerPlugin(FilePondPluginImagePreview, FilePondPluginFileValidateType);
interface FileUploaderProps {
    fileTypes: string[];
    onUploadComplete: (value: any) => void;
    url: string;
    name: string;
    labelIdle: string;
    salla: any;
}

export const FileUploader = (props: FileUploaderProps) => {
    const [files, setFiles] = useState([]);
    const [value, setValue] = useState('');

    return (

        <FilePond
            files={files}
            // @ts-ignore
            onupdatefiles={fileItems => setFiles(fileItems.map(fileItem => fileItem.file))}
            allowMultiple={false}
            dropOnPage
            required
            credits={false}
            labelIdle={props.labelIdle}
            acceptedFileTypes={props.fileTypes}
            server={{
                process: {
                    url: props.url,
                    onload: (response) => {
                        const url = JSON.parse(response).data;
                        setValue(url.filePath || url.url);
                        props.onUploadComplete(value);
                        return value;
                    },
                    onerror: (response) => {
                        return response;
                    },
                    headers: props.salla.api.getHeaders(),
                    ondata: (formData) => {
                        const file = formData.get('file');
                        formData.delete('file');
                        if (file instanceof Blob) {
                            formData.append(props.name, file);
                        }
                        return formData;
                    }
                }
            }}
        />

    );
};