
import type React from 'react';
import { useState, useCallback, type CSSProperties, useImperativeHandle, forwardRef } from 'react'
import SingleInput from './SingleInput';
import { inputDigitsOnly } from '../../helper';
export type OTPInputProps = {
    length: number;
    onChangeOTP: (otp: string) => void;
    onCompleted?: (otp: string) => void;
    autoFocus?: boolean;
    isNumberInput?: boolean;
    disabled?: boolean;
    inputStyle?: CSSProperties;
    inputClassName?: string;
} & JSX.IntrinsicElements['input']

export type OTPInputRef = {
    reset: () => void;
    focusFirstInput: () => void;

}

export const OTPInput = forwardRef<OTPInputRef, OTPInputProps>((props, ref) => {
    const {
        length,
        isNumberInput,
        autoFocus,
        disabled,
        onChangeOTP,
        onCompleted,
        inputClassName,
        inputStyle,
        ...rest
    } = props;

    const [activeInput, setActiveInput] = useState(0);
    const [otpValues, setOTPValues] = useState(Array<string>(length).fill(''));
    // Helper to return OTP from inputs
    const handleOtpChange = useCallback(
        (otp: string[]) => {
            const otpValue = otp.join('');
            onChangeOTP(otpValue);
            // Check if all inputs are filled and call onCompleted
            if (otpValue.length === length) {
                onCompleted?.(otpValue);
            }
        },
        [length, onChangeOTP, onCompleted],
    );
    const reset = useCallback(() => {
        setOTPValues(Array<string>(length).fill(''));
        setActiveInput(0);
    }, [length]);
    useImperativeHandle(ref, () => ({
        reset,
        focusFirstInput: () => {
            setActiveInput(0);
        }
    }));
    // Helper to return value with the right type: 'text' or 'number'
    const getRightValue = useCallback(
        (str: string) => {
            const changedValue = str;

            if (!isNumberInput || !changedValue) {
                return changedValue;
            }

            return Number(changedValue) >= 0 ? changedValue : '';
        },
        [isNumberInput],
    );

    // Change OTP value at focussing input
    const changeCodeAtFocus = useCallback(
        (str: string) => {
            const updatedOTPValues = [...otpValues];
            updatedOTPValues[activeInput] = str[0] || '';
            setOTPValues(updatedOTPValues);
            handleOtpChange(updatedOTPValues);
        },
        [activeInput, handleOtpChange, otpValues],
    );

    // Focus `inputIndex` input
    const focusInput = useCallback(
        (inputIndex: number) => {
            const selectedIndex = Math.max(Math.min(length - 1, inputIndex), 0);
            setActiveInput(selectedIndex);
        },
        [length],
    );

    const focusPrevInput = useCallback(() => {
        focusInput(activeInput - 1);
    }, [activeInput, focusInput]);


    const focusNextInput = useCallback(() => {
        focusInput(activeInput + 1);
    }, [activeInput, focusInput]);

    // Handle onFocus input
    const handleOnFocus = useCallback(
        (index: number) => () => {
            focusInput(index);
        },
        [focusInput],
    );

    // Handle onChange value for each input
    const handleOnChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const val = getRightValue(e.currentTarget.value);
            if (!val) {
                e.preventDefault();
                return;
            }
            if (val.length === length) {
              handleOnPaste(undefined, val.split(''));
              return;
            }
            changeCodeAtFocus(val);
            focusNextInput();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [changeCodeAtFocus, focusNextInput, getRightValue],
    );

    // Handle onBlur input
    const onBlur = useCallback(() => {
        setActiveInput(-1);
    }, []);

    // Handle onKeyDown input
    const handleOnKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            const pressedKey = e.key;

            switch (pressedKey) {
                case 'Backspace':
                case 'Delete': {
                    e.preventDefault();
                    if (otpValues[activeInput]) {
                        changeCodeAtFocus('');
                    } else {
                        focusPrevInput();
                    }
                    break;
                }
                case 'ArrowLeft': {
                    e.preventDefault();
                    focusPrevInput();
                    break;
                }
                case 'ArrowRight': {
                    e.preventDefault();
                    focusNextInput();
                    break;
                }
                default: {
                    if (pressedKey.match(/^[^a-zA-Z0-9]$/)) {
                        e.preventDefault();
                    }

                    break;
                }
            }
        },
        [activeInput, changeCodeAtFocus, focusNextInput, focusPrevInput, otpValues],
    );

    const handleOnPaste = useCallback(
      (e?: React.ClipboardEvent<HTMLInputElement>, otpArrays?: String[]) => {
        e?.preventDefault();

        const pastedData = otpArrays
          ? otpArrays
          : e?.clipboardData
              .getData('text/plain')
              .trim()
              .slice(0, length)
              .split('');

        if (pastedData && pastedData.length) {
          let nextFocusIndex = activeInput;
          const updatedOTPValues = [...otpValues];

          updatedOTPValues.forEach((val, index) => {
            if (pastedData.length > 0) {
              const changedValue = getRightValue(pastedData.shift() as string);

              if (changedValue) {
                updatedOTPValues[index] = changedValue;
                nextFocusIndex = index;
              }
            }
          });

          setOTPValues(updatedOTPValues);
          setActiveInput(Math.min(nextFocusIndex + 1, length - 1));
          handleOtpChange(updatedOTPValues);
        }
      },
      [activeInput, getRightValue, handleOtpChange, length, otpValues]
    );

    return (
        <div {...rest} dir="ltr">
            {Array(length)
                .fill('')
                .map((_, index) => (
                    <SingleInput
                        key={`SingleInput-${index}`}
                        type={isNumberInput ? 'number' : 'text'}
                        focus={activeInput === index}
                        value={otpValues?.[index]}
                        autoFocus={autoFocus}
                        onFocus={handleOnFocus(index)}
                        onChange={handleOnChange}
                        onInput={(e) => inputDigitsOnly(e.currentTarget)}
                        onKeyDown={handleOnKeyDown}
                        onBlur={onBlur}
                        onPaste={handleOnPaste}
                        autoComplete="one-time-code"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        // maxLength={1}
                        id={`otp-${index}`}
                        style={inputStyle}
                        className={inputClassName}
                        disabled={disabled}
                    />
                ))}			
        </div>
    )
})



export default OTPInput;